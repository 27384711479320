body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* NOTE: Move to separate css file */

.rdw-image-modal-size {
  display: none !important;
}

.comment-img {
  max-width: 100%;
}

.comment-img {
  max-width: 280px;
}
.jc-editor,
.devono-abstract,
.devono-guest-abstract,
.denovo-editor {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.jc-editor #toolbar {
  background: transparent;
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 0;
  width: calc(100% - 96px);
  overflow-x: scroll;
  overflow-y: hidden;
  margin-left: -12px;
}
.denovo-editor #toolbar {
  background: #f3f3f3;
  position: absolute;
  display: flex;
  align-items: center;
  top: 60px;
  width: calc(100% - 40px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  cursor: grab;
  margin-left: 0px;
}
.denovo-editor #toolbar::-webkit-scrollbar {
  display: block;
  height: 4px;
}
.denovo-editor #toolbar::-webkit-scrollbar-track {
  background: #f3f3f3;
}
.denovo-editor #toolbar::-webkit-scrollbar-thumb {
  background: #838383;
  border-radius: 20px;
}

.denovo-editor .ql-snow .ql-picker.ql-expanded .ql-picker-options {
  display: block;
  position: fixed;
  margin-top: -1px;
  top: inherit;
  left: auto;
  max-width: 192px;
  min-width: 192px;
  width: 192px;
  right: 20%;
  z-index: 9999999999999999 !important;
}
.jc-editor .ql-container{
  border: none !important;
}
.denovo-editor .ql-container {
  border: none !important;
  margin-top: 36px;
  font-family: 'Raleway';
  font-size: 16px;
}
.jc-editor .ql-editor {
  padding: 10px 0px;
  min-height: 76vh;
}
.jc-editor .ql-snow .ql-color.ql-picker.ql-color-picker,
.jc-editor .ql-snow.ql-toolbar button,
.denovo-editor .ql-snow .ql-color.ql-picker.ql-color-picker,
.denovo-editor .ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  color: #838383;
  background-color: #ffffff;
  width: 34px;
  height: 34px;
  padding: 8px;
  border-radius: 4px;
  margin-right: 12px;
}

.jc-editor .ql-snow .ql-color.ql-picker.ql-color-picker svg,
.jc-editor .ql-snow.ql-toolbar button svg,
.denovo-editor .ql-snow .ql-color.ql-picker.ql-color-picker svg,
.denovo-editor .ql-snow.ql-toolbar button svg,
.ql-snow .ql-toolbar button svg {
  width: 20px;
  height: 20px;
}

.jc-editor .ql-toolbar.ql-snow .ql-formats,
.denovo-editor .ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px;
  display: flex;
  flex-direction: row;
}

.jc-editor .ql-snow .ql-color-picker:hover,
.denovo-editor .ql-snow .ql-color-picker:hover,
.ql-snow.ql-toolbar button:hover,
.ql-snow.ql-toolbar button.ql-active {
  opacity: 1;
  background-color: #838383 !important;
  border-radius: 5px;
}
.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #ffffff !important;
}

.jc-editor .ql-snow .ql-picker.ql-expanded .ql-picker-options {
  display: block;
  position: fixed;
  margin-top: -1px;
  top: inherit;
  bottom: 13%;
  left: auto;
  max-width: 192px;
  min-width: 192px;
  width: 192px;
  right: 20%;
  z-index: 9999999999999999 !important;
}
.ql-snow .ql-color-picker .ql-picker-label,
.ql-snow .ql-icon-picker .ql-picker-label {
  padding: 0 !important;
}
.ql-toolbar.ql-snow .ql-picker-label {
  border: none !important;
}
.jc-editor .tooltip .tooltiptext {
  bottom: 150%;
  top: unset;
}
.jc-editor .tooltip .tooltiptext::after {
  top: 100%;
  bottom: unset;
  border: black transparent transparent transparent;
}
.devono-guest-abstract::before {
  content: '';
  width: 100%;
  height: 150px;
  position: absolute;
  bottom: -46px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 80%
  );
}
/* .denovo-editor .ql-container {
  min-height: 400px;
  max-height: calc(90vh - 200px);
} */
.denovo-editor .ql-editor{
  min-height: 400px;
  max-height: 500px;
  overflow: scroll;
}

.jc-comment img,
.devono-guest-abstract img,
.devono-abstract img {
  max-width: 95%;
  display: block;
  padding: 4px;
}
.jc-comment h1,
.devono-guest-abstract h1,
.devono-abstract h1 {
  font-size : 30px;
  line-height : 50px;
  font-weight: 500;
}
.jc-comment h2,
.devono-guest-abstract h2,
.devono-abstract h2 {
  font-size : 20px;
  line-height : 26px;
  font-weight: 600;
}
.jc-comment img[src$='video.png'] {
  width: 80px !important;
  height: 80px !important;
}
.jc-comment p {
  overflow-wrap: break-word;
}
.jc-comment video,
.devono-guest-abstract video,
.devono-abstract video {
  width: auto;
  height: auto;
  max-width: 95%;
  max-height: 350px;
}
.rdw-image-alignment-options-popup {
  border: none !important;
}
.rdw-image-alignment-options-popup div:nth-child(1),
.rdw-image-alignment-options-popup div:nth-child(2),
.rdw-image-alignment-options-popup div:nth-child(3) {
  visibility: hidden;
  position: relative;
}
.rdw-image-alignment-options-popup div:nth-child(1):after,
.rdw-image-alignment-options-popup div:nth-child(2):after,
.rdw-image-alignment-options-popup div:nth-child(3):after {
  visibility: visible;
  padding: 2px;
  position: absolute;
  top: 0;
  left: 0;
}
.rdw-image-alignment-options-popup div:nth-child(1):after {
  font-family: 'Font Awesome 5 Free';
  content: '\f036';
  font-weight: 900;
}
.rdw-image-alignment-options-popup div:nth-child(2):after {
  font-family: 'Font Awesome 5 Free';
  content: '\f037';
  font-weight: 900;
}
.rdw-image-alignment-options-popup div:nth-child(3):after {
  font-family: 'Font Awesome 5 Free';
  content: '\f038';
  font-weight: 900;
}
/* .jc-editor img[src$="Adobe_PDF_file_icon_32x32.png"]::after {
  content: " (PDF, " attr(alt) ")";
}
img[src$="Adobe_PDF_file_icon_32x32.png"][alt]:after {
  content: " (PDF, " attr(alt) ")" !important;
} */
.rightnav {
  top: 64px;
}
.leftnav {
  top: 64px;
}
.jc-comment .ql-attachment,
.devono-guest-abstract .ql-attachment,
.devono-abstract .ql-attachment{
  display: block !important;
}
.jc-editor img,
.jc-editor video,
.denovo-editor img {
  max-width: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  display: block;
  padding: 6px;
}
.jc-editor img::after {
  content: ' (PDF)';
}
.jc-editor img[src$='video-icon.png'] {
  width: 80px !important;
  height: 80px !important;
}
/* .jc-editor iframe {
  width: 80% !important;
  height: 75% !important;
} */
.jc-editor::-webkit-scrollbar {
  display: none;
}
.rdw-editor-toolbar {
  border: none !important;
}
.activity-white-icon,
.white-icon {
  filter: brightness(0) invert(1);
  width: 28px;
  height: 28px;
}
.rdw-image-modal-upload-option-image-preview {
  max-width: 100%;
  max-height: 50px !important;
}
.denovo-editor,
.jc-editor,
.jc-editor .ql-container,
.rdw-image-modal,
.rdw-embedded-modal,
.rdw-link-modal,
.rdw-colorpicker-modal {
  font-family: 'Raleway' !important;
}
.jceditor-toolbar .rdw-image-modal,
.jceditor-toolbar .rdw-embedded-modal,
.jceditor-toolbar .rdw-link-modal,
.jceditor-toolbar .rdw-colorpicker-modal {
  font-family: 'Raleway' !important;
  top: -225px !important;
}
.rdw-inline-wrapper {
  font-family: Bitter;
  color: #838383;
  font-weight: 700;
}
.jceditor-toolbar .rdw-image-modal {
  left: auto !important;
  right: -90px !important;
  box-shadow: 1px 1px 1px #bfbdbd !important;
}
.jceditor-toolbar .rdw-embedded-modal {
  left: -80px !important;
  right: auto !important;
}
@media screen and (max-width: 900px){
  .jc-comment h1,
  .devono-guest-abstract h1,
  .devono-abstract h1 {
      font-size : 18px;
      line-height : 24px;
      font-weight: 600;
  }
  .jc-comment h2,
  .devono-guest-abstract h2,
  .devono-abstract h2 {
      font-size : 16px;
      line-height : 22px;
      font-weight: 600;
  }
  .denovo-editor #toolbar::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 360px) {
  div.rdw-list-dropdown {
    margin: 0px 4px 6px 0px !important;
  }
  .rdw-colorpicker-wrapper {
    margin-right: 0px !important;
  }
}
@media screen and (max-width: 480px) {
  .jc-comment img {
    max-width: 95%;
  }
  .jceditor-toolbar {
    width: 80% !important;
  }
  .jc-comment iframe {
    max-width: 80% !important;
  }
  .jc-editor #toolbar {
    overflow: scroll;
  }
  .denovo-toolbar .rdw-link-modal {
    left: auto;
    right: -50px;
  }
  .jceditor-toolbar .rdw-image-modal {
    top: -245px !important;
    left: auto !important;
    right: -90px !important;
  }
  .jceditor-toolbar .rdw-embedded-modal {
    left: -50px !important;
    right: auto !important;
  }
  .jceditor-toolbar .rdw-colorpicker-wrapper {
    margin-right: 34px !important;
  }
  .jceditor-toolbar .rdw-colorpicker-modal {
    left: auto !important;
    right: -75px !important;
  }
  .rdw-option-wrapper > img,
  .rdw-link-wrapper > img {
    height: 25px !important;
  }
  .rdw-list-dropdown {
    margin: 0px 4px 6px 0px !important;
  }
  .rdw-inline-dropdown {
    margin: 0px 4px 6px 0px !important;
  }

  .rdw-option-wrapper {
    margin-left: 0 !important;
    margin-right: 4px !important;
  }
  /* .jc-editor .ql-snow .ql-color.ql-picker.ql-color-picker.ql-expanded{
    position: fixed;
  } */

  .jc-editor .ql-snow .ql-picker.ql-expanded .ql-picker-options {
    bottom: 48px;
  }
}

.rdw-option-wrapper:active {
  /* box-shadow: 0px 0px 0px #bfbdbd inset !important; */
  background: #838383 !important;
}

.rdw-inline-wrapper > .rdw-option-active,
.rdw-inline-wrapper > .rdw-option-wrapper:active,
.rdw-inline-wrapper > .rdw-option-wrapper:hover {
  box-shadow: none !important;
}

.rdw-inline-wrapper > .rdw-option-wrapper:hover,
.rdw-remove-wrapper > .rdw-option-wrapper:hover,
.rdw-image-wrapper > .rdw-option-wrapper:hover,
.rdw-embedded-wrapper > .rdw-option-wrapper:hover,
.rdw-link-wrapper > .rdw-option-wrapper:hover,
.rdw-colorpicker-wrapper > .rdw-option-wrapper:hover,
.rdw-list-wrapper > .rdw-option-wrapper:hover,
.rdw-inline-wrapper > .rdw-option-wrapper:active,
.rdw-remove-wrapper > .rdw-option-wrapper:active,
.rdw-image-wrapper > .rdw-option-wrapper:active,
.rdw-embedded-wrapper > .rdw-option-wrapper:active,
.rdw-link-wrapper > .rdw-option-wrapper:active,
.rdw-colorpicker-wrapper > .rdw-option-wrapper:active,
.rdw-list-wrapper > .rdw-option-wrapper:active,
/* *, */
.rdw-inline-wrapper > .rdw-option-active,
.rdw-remove-wrapper > .rdw-option-active,
.rdw-image-wrapper > .rdw-option-active,
.rdw-embedded-wrapper > .rdw-option-active,
.rdw-link-wrapper > .rdw-option-active,
.rdw-colorpicker-wrapper > .rdw-option-active,
.rdw-list-wrapper > .rdw-option-active {
  box-shadow: none !important;
  /* background: #f3f3f3; */
  border: 1px #262626 solid !important;
}

.rdw-option-wrapper > img,
.rdw-link-wrapper > img {
  height: 30px;
}

.rdw-option-wrapper {
  margin-left: 0;
  margin-right: 12px;
}
.jceditor-toolbar .rdw-list-dropdown {
  margin: 0px 34px 6px 0px;
}
.jceditor-toolbar .rdw-list-dropdown .rdw-dropdown-optionwrapper {
  top: -194px !important;
}
.jceditor-toolbar .rdw-inline-dropdown .rdw-dropdown-optionwrapper {
  top: -234px !important;
}
.rdw-list-dropdown img,
.rdw-inline-dropdown img,
.rdw-link-dropdown img {
  height: 25px !important;
}
.rdw-link-wrapper > .rdw-option-wrapper > img {
  height: 20px !important;
}

/* denovo editor */
.denovo-toolbar .rdw-list-dropdown {
  margin: 0px 3px 6px 3px !important;
}
.denovo-editor iframe {
  width: -webkit-fill-available;
  max-height: 250px;
  max-width: 100%;
}
.denovo-toolbar .rdw-image-modal,
.denovo-toolbar .rdw-embedded-modal {
  left: auto !important;
  right: 0 !important;
}

.ql-editor ::-webkit-scrollbar {
  width: 6px;
}
.ql-editor ::-webkit-scrollbar-track {
  background-color: red;
  border-radius: 10px;
}
.ql-editor ::-webkit-scrollbar-thumb {
  background-color: red;
  border-radius: 9px;
}

.btn-speed-dial{
  border-radius: 4px !important;
  height: 40px;
  font-family: 'Raleway' !important;
  text-transform: none !important ;
  justify-content: left !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500;
  color: #262626 !important;
  min-width: 170px !important;
  padding-left: 15px !important;
  background-color: #FFFFFF !important;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}
button.ql-header[value="2"] svg {
  width: 16px !important;
}
/* .jc-editor button::before {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  background-color: #333;
  color: #fff;
  font-size: 14px;
  font-family: 'Raleway';
  border-radius: 3px;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1;
}

.jc-editor button:hover::before {
  opacity: 1;
} */

