@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@500;600&display=swap");

.app-container {
  text-align: center;
  /* overflow-y: scroll; */
}

body {
  background-color: #f3f3f3;
  text-transform: none;
}

.mobile-icon {
  filter: brightness(0) invert(1);
  margin-right: 6px;
  height: 24px;
  width: 24px;
}

.mobile-paper-icon {
  margin-top: 5px;
  margin-right: 10px;
  height: 24px;
  width: 24px;
}

.mobile-save-icon {
  margin-right: 6px;
  margin-left: 0px;
}

.mobile-drawer-box {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding-bottom: 5px;
}

.mobile-botton {
  font-size: 14px !important;
  font-family: "Raleway" !important;
  text-transform: none !important;
  height: 28px !important;
  width: auto !important;
}

.drawer-typography {
  font-size: 14px !important;
  line-height: 10px !important;
  margin-top: -5px !important;
  font-weight: 600;
}

.keyword-chip {
  margin-left: 5px !important;
  margin-top: 8px !important;
  color: #262626 !important;
  background-color: #f3f3f3 !important;
  font-family: "Raleway" !important;
  font-weight: 600 !important;
  font-size: 15px !important;
}

.keyword-chip-mobile {
  text-transform: capitalize !important;
  height: 20px !important;
  margin-top: 5px !important;
  margin-left: 5px !important;
  color: #262626 !important;
  background-color: #f3f3f3 !important;
  font-family: "Raleway" !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}

.desktop-actions {
  font-family: "Raleway" !important;
  text-transform: "none" !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 18px !important;
}

.article-box-desktop {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.spinner {
  position: absolute;
  height: 100px;
  width: 100px;
  top: 50%;
  left: 47%;
  margin-top: -50px;
}

/* Bottom Navigation Bar */
/* .css-bkx5t6-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected {
  border-top: 2px solid;
}
.css-bkx5t6-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected
  svg
  path {
  fill: #ef5b50;
}
.css-imwso6-MuiBottomNavigationAction-label {
  font-family: "Raleway" !important;
  font-size: 10px !important;
  padding-top: 4px !important;
  font-weight: 500 !important;
  width: 70px !important;
}

/* CSS for Journal club shape(temporary) */
.jcShape {
  display: block;
  width: 100%;
  margin-top: -20px;
  color: #ef5b50;
  filter: url("#jcShapef");
  z-index: 1;
}

.jcShape-mobile {
  display: block;
  width: 100%;
  margin-top: -20px;
  color: #ef5b50;
  filter: url("#jcShapef");
  z-index: 1;
}

.jcShape-tab {
  display: block;
  width: 100%;
  margin-top: -20px;
  color: #ef5b50;
  filter: url("#jcShapef");
  z-index: 1;
}

.jcShape::before {
  content: "";
  display: block;
  padding-top: 15%;
  z-index: 9;
  background: currentColor;
  clip-path: polygon(0 0, 100% 0, 97% 100%, 3% 100%);
}

.jcShape-mobile::before {
  content: "";
  display: block;
  padding-top: 30%;
  z-index: 9;
  background: currentColor;
  clip-path: polygon(0 0, 100% 0, 97% 100%, 3% 100%);
}

.jcShape-tab::before {
  content: "";
  display: block;
  padding-top: 20%;
  z-index: 9;
  background: currentColor;
  clip-path: polygon(0 0, 100% 0, 97% 100%, 3% 100%);
}

.link-class {
  color: #262626 !important;
  text-decoration: none !important;
}

.link-class:hover {
  color: #ffffff !important;
}

.report-nav-root_text {
  color: #565656 !important;
}

.report-nav-root_text:hover {
  color: #ef5b50 !important;
}

.commentNoteTable td:last-child>div {
  color: #ef5b50;
  visibility: hidden;
}

.commentNoteTable:hover td:last-child>div {
  visibility: unset;
}

.disableTableRow {
  background-color: #F3F3F3;
  opacity: 0.6;
  pointer-events: none;
}
.disableTableRowManage {
  background-color: #F3F3F3;
  opacity: 0.6;
}

/* cursor: 'pointer', textDecoration: 'None', "&:hover": { textDecoration: 'underline', textDecorationColor: '#262626' */
.reportTabArticleLink {
  cursor: pointer;
  text-decoration: none;
}

.reportTabArticleLink:hover {
  text-decoration: underline;
  text-decoration-color: #262626;
}

/* comment alert  */

/* .reportlogoCommentNote {
  cursor: pointer;
}

.reportlogoCommentNote:hover {
  color: #ef5b50;
} */

/* manage tab css */
.managetab-content-box {
  border: 1px solid #838383;
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.managetab_heading {
  display: flex !important;
  justify-content: space-between !important;
}

.managetab_heading-content {
  font-weight: 600;
  font-size: 24px !important;
  color: #838383;
}

.managetab-content {
  display: flex;
  flex-flow: column !important;
  text-align: start;
  margin-top: 16px !important;
  font-weight: 600 !important;
  font-size: 18px !important;
}

.manage_radio_label {
  color: #838383;
  font-size: 18px !important;
  line-height: 26px !important; 
  font-family: "Raleway" !important;
  font-weight: 700 !important;
}


.workinprogress-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .workinprogress-logo {
    animation: workinprogress-logo-spin infinite 20s linear;
  }
}

@keyframes workinprogress-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#form-file-upload {
  height: 270px;
  padding-right: 32px;
  margin-left: 18px;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  font-family: 'Raleway';
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-radius: 4px;
  border-style: dashed;
  border-color: #262626;
  background-color: #ffffff;
}

#label-file-upload.drag-active {
  background-color: #F3F3F3;
}

.upload-button {
  cursor: pointer;
  font-family: 'Raleway';
  color: #ef5b50;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

/* CSS from landing Page */

.content_wraper {
  margin: 1% 28%;
  text-align: center;
}

.content_wraper_large {
  margin: 1% 16% !important;
}
.jc-landing-page-heading {
  font-family: 'Bitter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  margin-top: 1.7em;
}
.jc-landing-page-paragraph {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 1em;
}
.jc-red {
  color: #ef5b50;
}

.jc-input {
  font-family: 'Raleway';
  font-size: 1rem;
  width: 50%;
  outline: none;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 1rem 0.7rem;
  color: #262626;
  font-weight: 700;
  transition: 0.1s ease-out;
}
.jc-input:focus {
  border-color: #ef5b50;
}
.jc-input:focus + label {
  color: #262626;
  top: 0;
  transform: translateY(-50%) scale(0.9);
}
.jc-input:not(:placeholder-shown) + label {
  top: 0;
  transform: translateY(-50%) scale(0.9);
}

.jc-error {
  font-family: 'Raleway';
  font-size: 12px;
  font-weight: 600;
}
.jc-bodycopy {
  font-family: 'Raleway';
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: white;
  z-index: 5;
}
footer hr {
  margin-bottom: 1em;
  width: 60%;
  background: #e1e1e1;
}
.footer-copyright {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 1em;
}
.mobile-image {
  display: none;
}
.hide-mobile {
  display: none;
}

@media screen and (max-width: 820px) {
  .content_wraper {
    margin: 1% 25%;
    text-align: center;
  }
  .activity-white-icon {
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 767px) {
  header img {
    width: 55%;
    margin: 3.5% auto;
  }
  .button_container-desktop {
    display: none;
  }

  .content_wraper,
  .content_wraper-large {
    margin: 0 14% !important;
  }
  .jc-landing-page-heading {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    margin-top: 1.5em;
  }

  .jc-landing-page-paragraph {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin: 2em 0px;
  }
  .crousal-wraper {
    display: none;
  }
  .button_container-mobile {
    margin-top: 1em;
    display: flex;
  }
  .button_wraper a {
    text-decoration: none;
    background-color: #ef5b50;
    padding: 5px 7px;
    display: inline-block;
    cursor: pointer;
    border: 1px solid #ef5b50;
    box-shadow: unset;
    color: white;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }
  .button_wraper {
    margin: 0px auto;
  }
  .mobile-image {
    display: flex;
  }
  .mobile-image img {
    width: 90%;
    margin: 0 auto;
  }
  footer hr {
    margin-top: 0px;
    margin-bottom: 0.5em;
    width: 60%;
  }
  .footer-copyright {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 1em;
  }
  .jc-bodycopy {
    font-size: 12px;
    line-height: 18px;
  }
  .jc-input {
    width: 100%;
    height: 10px;
    font-size: 12px;
  }
  .hide-desktop {
    display: none;
  }
  .hide-mobile {
    display: block;
  }
}
